import PropTypes from 'prop-types';
import { Box, IconButton, Popover } from '@mui/material';

// @mu icons
import SettingsIcon from '@mui/icons-material/Settings';

import { useEffect, useState } from 'react';

CustomPopover.propTypes = {
    popoverContent: PropTypes.node,
    icon: PropTypes.node,
    anchorOrigin: PropTypes.object,
    onPopoverClose: PropTypes.func,
    transformOrigin: PropTypes.object,
    disabled: PropTypes.bool,
    isOpenFromCourse: PropTypes.bool,
    handleGetFolderDataByGroup: PropTypes.func,
    closeUploadVideoPopover: PropTypes.bool,
    closeGalleryAccessPopover: PropTypes.bool,
}


export default function CustomPopover( {popoverContent, icon , anchorOrigin, transformOrigin, disabled, onPopoverClose, isOpenFromCourse, closeUploadVideoPopover, closeGalleryAccessPopover, handleGetFolderDataByGroup, ...others} ) {
    const [openedPopover, setOpenedPopover] = useState(false);
      const [anchorEl, setAnchorEl] = useState(null);
      const handleOpenPopover = (event) => {
        if(disabled)
            return
        event.stopPropagation()
        setOpenedPopover(true)
        setAnchorEl(event.currentTarget);

        if(isOpenFromCourse)
            handleGetFolderDataByGroup()
    };

    const handleClose = () => {
        setOpenedPopover(false)
        setAnchorEl(null);
        if(onPopoverClose)
            onPopoverClose()       
    };
    
    useEffect(() => {
        if(closeUploadVideoPopover || closeGalleryAccessPopover)
            handleClose()
    },[closeUploadVideoPopover, closeGalleryAccessPopover]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
        <Box {...others}>
            {!icon && <IconButton 
                name="autoplay"
                size="small"
                disabled={disabled}
                sx={{ marginRight: '0' }}
                onClick={handleOpenPopover}
            >
                <SettingsIcon  sx={{ fontSize: 22, cursor: 'pointer' }} />                     
            </IconButton>} 
            {
                icon && <Box onClick={handleOpenPopover}
                >{icon}</Box>
            } 
            <Popover 
                sx={{
                    ml:.5,
                    '& .MuiCheckbox-root': {
                        p: 0,
                        mr: .5,
                        ml: .5,
                    }                                        
                }}
                open={openedPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={anchorOrigin || {
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={transformOrigin || {
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {popoverContent}
            </Popover>
    </Box>
  );
}

                   