import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import SpeedIcon from '@mui/icons-material/Speed';

CircularProgressWithLabel.propTypes={
    totalPercentage:PropTypes.any,
    total:PropTypes.any,
    numbers:PropTypes.any,
    displayPercentageValue:PropTypes.bool
}

export default function CircularProgressWithLabel({totalPercentage,total,numbers,displayPercentageValue}) {
    const percentageValue = (numbers/total)*totalPercentage
    const props={value:(percentageValue >= totalPercentage ? totalPercentage : percentageValue)}
    const progressVisibility = (!displayPercentageValue && numbers <=3) || ((displayPercentageValue && percentageValue > totalPercentage))  ? 'hidden':''
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
         <CircularProgress
            variant="determinate"
            sx={{
                color: "white",
                visibility: progressVisibility
            }}
            size={45}
            thickness={4}
            value={totalPercentage}
        />
        <CircularProgress size={45} variant="determinate" {...props} sx={{ position: 'absolute',visibility: progressVisibility}} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!displayPercentageValue 
            ? 
              <>
                {numbers <=3 ? <WarningAmberIcon sx={{mb:.5, ml:.5, fontWeight: 'bold'}} fontSize="large" color='warning'/>
                : <Typography
                    variant="caption"
                    component="div"
                    sx={{fontWeight: 'bold'}}
                    color="text.secondary"
                  >{numbers}</Typography>
                }
              </>
              
            :
              <>
                {percentageValue > totalPercentage ? <SpeedIcon sx={{mb:.5, ml:.5}} fontSize="large"/> 
                :
                <Typography
                  sx={{fontSize:'0.65rem', fontWeight: 'bold'}}
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >{percentageValue.toFixed()}% </Typography> 
                }
              </>
              
          }
        </Box>
      </Box>
    );
  }