// ----------------------------------------------------------------------

import { thinScrollbarStyle } from "src/utils/common";

export default function Popover(theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.dropdown,
          borderRadius: Number(theme.shape.borderRadius) * 1.5,
          '&:not(.no-thin-scrollbar)': thinScrollbarStyle,
        },
      },
    },
  };
}
