import * as moment from 'moment'

import ReactGA from 'react-ga4'
import { HOST_API, HUBSPOT_REDIRECT } from 'src/config';

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const getVideoSourceType = (url) => {
  const el = document.createElement('a');
  el.href = url;
  const host = el.hostname;

  if (host.indexOf('youtu') > -1 && host.indexOf('converter.org') === -1) {
    return 'youtube';
  }
  if (host.indexOf('vimeo') > -1 && host.indexOf('player.vimeo') === -1) {
    return 'vimeo';
  }
  if (host.indexOf('dropbox') > -1) {
    return 'dropbox';
  }
  if (host.indexOf('facebook') > -1) {
    return 'facebook';
  }
  if (host.indexOf('instagram') > -1) {
    return 'instagram';
  }
  if (host.indexOf('drive.google.com') > -1) {
    return 'gdrive';
  }
  if (host.indexOf('nyc3.digitaloceanspaces.com') > -1) {
    return 's3';
  }
  return 'unknown';
};

export const jsonToUrlParams = (params) =>
  Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

export const randomString = (length, chars) => {
  /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
  let mask = '';
  let result = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';

  for (let i = length; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
  return result;
};

// export const originalPlayerSettings = () => {
//   const now = new Date();
//   return {
//     type: 'upload',
//     skin: 'default',
//     skinStyle: 'modern',
//     showControls: false,
//     fullScreen: true,
//     buffer: true,
//     autosize: true,
//     overlays: [],
//     subtitleTracks: [],
//     finishAction: {},
//     youtubeSource: '',
//     button: { animation: 'pulse', size: 'default', hasBorder: false, shape: 'default' },
//     share: { active: false, url: '', tags: '' },
//     jsonLD: false,
//     repeatTime: now,
//     liveTime: now,
//     timeRemaining: true,
//     contest: {},
//     repeatDays: {
//       Monday: false,
//       Tuesday: false,
//       Wednesday: false,
//       Thursday: false,
//       Friday: false,
//       Saturday: false,
//       Sunday: false,
//     },
//     colors: {
//       skin: 'rgba(255, 255, 255, 0)',
//       controls: 'rgba(255, 255, 255, 1)',
//       bars: 'rgba(0, 0, 0, 1)',
//       shadows: 'rgba(0, 0, 0, .33)',
//     },
//   };
// };

export const sendData = {
  email: true,
  device: false,
  date: true,
  smsPhones: false,
  smsVerified: false,
  videos: false,
  funnel: false,
  answers: [],
  videosGroup: false,
  videosGroups: [],
  watchedVideos:[],
  devices: { Phone: false, Tablet: false, Desktop: false }
};

export const sendCommentData = {
  email: true,
  device: false,
  date: true,
  smsPhones: false,
  smsVerified: false,
  videos: false,
  funnel: false,
  answers: [],
  // videosGroup: false,
  // videosGroups: [],
  watchedVideos:[],
  devices: { Phone: false, Tablet: false, Desktop: false }
};

export const generateViewerDataForSegments = (result) => {
  const viewer = result;
  let email = 'No Email';
  let phone = '';
  let viewerID = 0;
  viewer.views.forEach((value) => {
    Object.keys(value).forEach((k) => {
      const v = value[k];
      if (v && k !== 'data') {
        viewer[k] = v;
      }
      if (v && k === 'phone') {
        phone = v;
      }
      if (v && k === 'contactData') {
        viewer.contactData = JSON.parse(v);
      }
      if (v !== '' && k === 'email') {
        email = v;
      }
      if (v !== '' && k === 'viewerID') {
        viewerID = v;
      }
    });
  });
  viewer.email = email;
  viewer.phone = phone;
  viewer.viewerID = viewerID;
  return viewer;
};

export const fontFamilies = [
  { label: 'monospace', value: 'monospace' },
  { label: 'Arial', value: 'Arial' },
  { label: 'Arial Black', value: 'Arial Black' },
  { label: 'Comic Sans MS', value: 'Comic Sans MS' },
  { label: 'Courier New', value: 'Courier New' },
  { label: 'Helvetica', value: 'Helvetica' },
  { label: 'sans-serif', value: 'sans-serif' },
];

export const fontSizes = [
  { label: 'Small', value: '30px' },
  { label: 'Medium', value: '45px' },
  { label: 'Large', value: '52px' },
];

export const buttonSizes = [
  { label: 'Small', value: 'small' },
  { label: 'Default', value: 'default' },
  { label: 'Big', value: 'big' },
];

export const buttonAnimations = [
  { label: 'Pulse', value: 'pulse' },
  { label: 'None', value: 'none' },
  { label: 'Tada', value: 'tada' },
  { label: 'Flash', value: 'flash' },
];

export const bandwidth=[
  { label: '0',  value: '0' },
  { label: '200',  value: '200' },
  { label: '400',  value: '400' },
  { label: '600',  value: '600' },
  { label: '800',  value: '800' },
  { label: '1000', value: '1000' },
  { label: '2000', value: '2000' },
  { label: '3000', value: '3000' },
  { label: '4000', value: '4000' },
  { label: '5000', value: '5000' },
  { label: '6000', value: '6000' },
  { label: '7000', value: '7000' },
  { label: '8000', value: '8000' },
  { label: '10000', value: '10000' },
  { label: '15000', value: '15000' },
  { label: '30000', value: '30000' },
]

export const buttonShapes = [
  { label: 'Default', value: 'default' },
  { label: 'Circle', value: 'circle' },
  { label: 'Square', value: 'square' },
];

export const buttonPositions = [
  { label: 'Center', value: 'center' },
  { label: 'Top Left Corner', value: 'topleft' },
  { label: 'Top Right Corner', value: 'topright' },
  { label: 'Bottom Left Corner', value: 'bottomleft' },
  { label: 'Bottom Right Corner', value: 'bottomright' },
];
export const toHHMMSS = (value,isHour = true) => {
  const sec = parseInt(value, 10);
    let hours   = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - (hours * 3600)) / 60);
    let seconds = sec - (hours * 3600) - (minutes * 60);

    if (hours < 10) {hours   = "0".concat(hours);}
    if (minutes < 10) {minutes = "0".concat(minutes);}
    if (seconds < 10) {seconds = "0".concat(seconds);}
    if(isHour)
      return [hours,minutes,seconds]
          .join(":")

    return [minutes,seconds]
      .join(":")
}

export const createWindowPopup = (url, w , h , name) => {
  let width1=0;
  let height1=0;
  let windowObj = null;
  // eslint-disable-next-line no-restricted-globals
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left; // eslint-disable-next-line no-restricted-globals
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top; // eslint-disable-next-line no-restricted-globals
  if(window.innerWidth)
    width1 = window.innerWidth
  else if(document.documentElement.clientWidth)
    width1 = document.documentElement.clientWidth
  else // eslint-disable-next-line no-restricted-globals
    width1 = screen.width
  if(window.innerHeight)
    height1 = window.innerHeight
  else if(document.documentElement.clientHeight)
      height1 = document.documentElement.clientHeight
  else // eslint-disable-next-line no-restricted-globals
      height1 = screen.height

  const left1 = Number(((width1 / 2) - (w / 2)) + dualScreenLeft);
  const top1 = Number(((height1 / 2) - (h / 2)) + dualScreenTop);

  windowObj = window.open(url, name, `'scrollbars=yes, width=${w}', height=${h}', top=${top1}', left=${left1}`); 
   
  return windowObj;
}

export const openNewWindow = (name, userID) => {
  let windowObj = null
  if (name === 'aWeber') {
    const url = 'https://auth.aweber.com/1.0/oauth/authorize_app/2d437215';
    windowObj = createWindowPopup(url,620,395,name)
  }
  if (name === 'HubSpot') {
    const url = `https://app-eu1.hubspot.com/oauth/authorize?client_id=1a316f14-e1c1-481c-a0f3-4a526731ac0d&redirect_uri=${HUBSPOT_REDIRECT}&scope=timeline%20crm.objects.contacts.read%20crm.objects.contacts.write`;
    windowObj = createWindowPopup(url,800,600,name);
  }
  if (name === 'vimeo') {
    const url = 'https://api.vimeo.com/oauth/authorize?client_id=d73cd9db19a9bd3d8cd8f9fd9fcf733abeadea5f&response_type=code&redirect_uri=https://app.spotlightr.com/authvimeo/&state=XXXXXX&scope=video_files';
    windowObj = createWindowPopup(url,620,395,name);
  }

  if(name === 'GoogleDrive') {
    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=1013976847202-l8p7a98nvodjq5jqu7lg8muusts3lrqr.apps.googleusercontent.com&redirect_uri=https://api.spotlightr.com/driveAuth&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.activity.readonly&access_type=offline&state=${userID}`;
    windowObj = createWindowPopup(url,620,395,name);
  }

  return windowObj
}
export const getVideoDuration=(seconds)=>{
  seconds = parseInt(seconds,10);
  let videoLength = '';
  const duration = moment.duration(seconds, 'seconds');

  if (duration.days() > 0) {
            videoLength = duration.days() < 10 ? `${"0".concat(String(duration.days()))}` : String(duration.days());
            videoLength = `${videoLength} ${':'}}`;
  }
  if (duration.hours() > 0) {
            videoLength = duration.hours() < 10 ? `${"0".concat(String(duration.hours()))}` : String(duration.hours());
            videoLength = `${videoLength} ${':'}`;
  }
  videoLength =  `${videoLength} ${duration.minutes() < 10 ? "0".concat(String(duration.minutes())) : String(duration.minutes())}`
  videoLength =  `${videoLength} ${':'}`;
  videoLength = `${videoLength} ${duration.seconds() < 10 ? "0".concat(String(duration.seconds())) : String(duration.seconds())}`;

  return videoLength;
}
export const bytesToSize = (bytes, data) =>{
  const sizes = data ? ['B', 'KB', 'MB', 'GB ', 'TB'] : ['', 'K', 'M', 'M', 'M'];  
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)),10);
  if (i === 0) return `${bytes} ${' '} ${sizes[i]}`;
  let bytesData = (bytes / 1000 ** i).toFixed(1)
  bytesData = `${bytesData} ${sizes[i]}`
  return bytesData;
};
export const getTimeInSeconds=(time)=>moment.duration(time, 'seconds').asSeconds();

export const getTimeAsObject=(time)=>moment.duration(time, 'seconds')

export const integrationImages=[
  {key:'mailChimp',image:'https://app.spotlightr.com/assets/images/integrations/mailchimp.jpg'},
  {key:'getResponse',image:'https://app.spotlightr.com/assets/images/integrations/getresponse.jpg'},
  {key:'activeCampaign',image:'https://app.spotlightr.com/assets/images/integrations/activecampaign.jpg'},
  {key:'GoogleAnalytics',image:'https://app.spotlightr.com/assets/images/integrations/analytics.png'},
  {key:'aWeber',image:'https://app.spotlightr.com/assets/images/integrations/aweber.jpg'},
  {key:'Custom HTML Form',image:'https://app.spotlightr.com/assets/images/integrations/html.jpg'},
  {key:'customS3',image:'https://app.spotlightr.com/assets/images/integrations/s3.jpg'},
  {key:'interspire',image:'https://app.spotlightr.com/assets/images/integrations/interspire.jpg'},
  {key:'HubSpot',image:'https://app.spotlightr.com/assets/images/integrations/hubspot.png'},
  {key:'email',image:'https://app.spotlightr.com/assets/images/integrations/email.png'},
  {key:'intercom',image:'https://app.spotlightr.com/assets/images/integrations/intercom.png'},
  {key:'Webhook',image:'https://app.spotlightr.com/assets/images/integrations/webhook.png'},
  {key:'ConvertFox',image:'https://app.spotlightr.com/assets/images/integrations/convertfox.jpg'},
  {key:'Stripe',image:'https://app.spotlightr.com/assets/images/integrations/stripe.jpg'},
  {key:'MailerLite',image:'https://app.spotlightr.com/assets/images/integrations/MailerLite.png'},
  {key:'zoom',image:'https://app.spotlightr.com/assets/images/integrations/zoom.png'},
  {key:'GoogleDrive',image:'https://app.spotlightr.com/assets/images/integrations/GoogleDrive.png'},
]
export const emailRegex = /\S+@\S+\.\S+/;
export const reduceArrayOfObjectByKey=(data,key)=>(
  data.reduce((acc, currentObj) => {
    const x = acc.find(item => item[key] === currentObj[key]);
    if (!x)
      return acc.concat([currentObj]);
    return acc;

  }, [])
)
export   const initAiConfig = {
  summary:false,
  chapters:false,
  questions:false,
}

export const ExportfileNameArray=[
  {
    id:'Quizzes',
    name:'Quizzes'
  },
  {
    id:'Galleries',
    name:'Galleries'
  },
  {
    id:'Courses',
    name:'Courses'
  },
  {
    id:'Videos',
    name:'Videos'
  },
  {
    id:'Reports',
    name:'Reports'
  },
  {
    id:'Playlists',
    name:'Playlists'
  },
  {
    id:'Playlists',
    name:'Playlists'
  },
  {
    id:'MyFiles',
    name:'MyFiles'
  },
  {
    id:'Audience',
    name:'MyStudents'
  }
]

export const noThumbnail ='https://images.spotlightr.com/video/image?id=0&optimizer=image&width=200'

export const API_CONFIG={
  url:`${HOST_API}`,
  'assets': '.cdn.spotlightr.com/assets/',
  'app': '.spotlightr.com/#/',
  'publish':'.cdn.spotlightr.com/'
}
export const getModuleShareUrl=(user,encodedId,type,customDomain = null,data = null, subdomain = null)=>{
  let shareURL = '';
  if(type === "playlist"){
    if (customDomain && customDomain !== null)
      shareURL = `https://${customDomain}/watch/playlist/${encodedId}`;
    else
      shareURL = `https://${subdomain || user.subdomain}${API_CONFIG.publish}watch/playlist/${encodedId}`;
  }
  if(type === "video"){
    if (customDomain && customDomain !== null)
      shareURL = `https://${customDomain}/watch/${encodedId}`;
    else
      shareURL = `https://${subdomain || user.subdomain}${API_CONFIG.publish}watch/${encodedId}`;
  }
  if(type === "course" || type === "gallery"){
    if (customDomain && customDomain !== null)
      shareURL = `https://${customDomain}/watch/${type}/${encodedId}`;
    else
      shareURL = `https://${subdomain || user.subdomain}${API_CONFIG.publish}watch/${type}/${encodedId}`;
  }
  
  if(type === "quiz"){
    if (customDomain && customDomain !== null)
      shareURL = `https://${customDomain}/watch/quiz/${encodedId}`;
    else if(data && data.data && data.data.themeData && data.data.themeData.domain)
      shareURL = `https://${data.data.themeData.domain}/watch/quiz/${encodedId}`;
    else
    shareURL = `https://${subdomain || user.subdomain}${API_CONFIG.publish}watch/quiz/${encodedId}`;
  }
  return shareURL;
}
// export const TranscribeDefaultPayment={
//   amount : 1,
//   quantity:1,
//   description:'Transcribe Minutes',
//   column:'transcribeMinutes'
// }
export const DefaultPayment=[{
  "transcribeMinutes":[{
    id:1,
    amount : 20,
    quantity:150,
    description:'Transcribe & AI Minutes',
    column:'transcribeMinutes'
  },
  {
    id:2,
    amount : 60,
    quantity:500,
    description:'Transcribe Minutes',
    column:'transcribeMinutes'
  },
  {
    id:3,
    amount : 100,
    quantity:1000,
    description:'Transcribe Minutes',
    column:'transcribeMinutes'
  }],
  "credit":{
    description:'Credit',
    column:'credit',
    amount:0,
  },
  "smsCredits":{
    amount : 25,
    quantity:250,
    description:'SMS Credits',
    column:'smsCredits'
  },
  "streamMinutes":[
    {
      id:1,
      amount : 5,
      quantity:100,
      description:'Streaming Minutes',
      column:'streamMinutes'
    },
    {
      id:2,
      amount : 45,
      quantity:1000,
      description:'Streaming Minutes',
      column:'streamMinutes'
    }
  ],
  "watchMinutes":
  [
    {
      id:1,
      amount : 3,
      quantity:1000,
      description:'Watch Minutes',
      column:'watchMinutes'
    },
    {
      id:2,
      amount : 25,
      quantity:10000,
      description:'Watch Minutes',
      column:'watchMinutes'
    }
  ]

}]
export const lockLabels=[
  {
    type:'default',
    labels:[{
      lockTitle:'Lock Title',
      lockButton:'Unlock Button'
    }]
  },
  {
    type:'share',
    labels:[{
      lockTitle:'Lock Title',
      lockButton:'Link To Share'
    }]
  }
]

// eslint-disable-next-line no-unused-vars
export const removeProperty = (propKey, { [propKey]: propValue, ...rest }) => 
  // console.log(propValue)
   rest
;

export const checkExists = (array,prop) => {
    const isExists = array.indexOf(prop) > -1
    return isExists
}
export function mapLicenceToNew(licence) {
  // NEW -> ARRAY OF OLD
  const licenceMapping = {
    'LIGHT': ['SPARK'],
    'PLUS': ['AURORA'],
    'PREMIUM': ['POLARIS'],
    'SCALE': ['SUPERNOVA'],
  }
  if(Object.keys(licenceMapping).includes(licence))
    return licence

  return Object.keys(licenceMapping).find(key => licenceMapping[key].includes(licence)) || licence;
}
export const copyTextToClipboard = async (text, gmailPreview) => {
  if(!gmailPreview){
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else document.execCommand('copy', true, text);
    return;
  }
  function listener(e) {
    e.clipboardData.setData("text/html", text);
    e.clipboardData.setData("text/plain", text);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
};

export const trackEventActions=(eventObj)=>{
  ReactGA.event(eventObj)
}
export const trackEventActionsFunction=(categoryValue,actionValue,labelValue)=>{
  labelValue = window.isApplet ? `Applet ${labelValue}` : labelValue 
  const eventObj={
    category: categoryValue,
    action: actionValue,
    label: labelValue
  }
  trackEventActions(eventObj)
}
export const bindScriptToHead = (code) => {
  const script = document.createElement('script');
  
  script.type = 'text/javascript';
  script.text = code;
  document.body.appendChild(script);
}

export const executeCrazyEggScript = ( key ) => {  
  if(key === 'signup') bindScriptToHead(`(window.CE_API || (window.CE_API=[])).push(function(){
		CE2.converted("b5835f12-3a45-4969-b66b-82c55c29c363");
	});`) 
  if(key === 'subsription-create') bindScriptToHead( `(window.CE_API || (window.CE_API=[])).push(function(){
		CE2.converted("d8feb691-11ec-450c-86ae-204823db27c4");
	});`)
  if(key === 'add-card') bindScriptToHead(`(window.CE_API || (window.CE_API=[])).push(function(){
		CE2.converted("0f79dc04-b183-4fdd-b286-2068c1ec6559");
	});`) 

} 
export const arrayToObject = (items, getKey) => {
  let result = null
  result =  items.reduce((a, v) => {
      a[getKey(v)] = v;
      return a;
  }, {});
  return result
};
export const parseNewTime =(newTime) => {
  const parsedTime = {};
  Object.keys(newTime).forEach((property)=>{
      const value = newTime[property]
      if (property === 'seconds' && value > 59) {
          parsedTime.minutes = parsedTime.minutes ? parsedTime.minutes + parseInt(value / 60,10) : parseInt(value / 60,10);
          parsedTime.seconds = value % 60;

          return;
      }
      if (property === 'minutes' && value > 59) {
          parsedTime.hours = parsedTime.hours ? parsedTime.hours + parseInt(value / 60,10) : parseInt(value / 60,10);
          parsedTime.minutes = parsedTime.minutes ? parsedTime.minutes + value % 60 : value % 60;

          return;
      }
      if (value) {
          parsedTime[property] = parsedTime[property] ? parsedTime[property] + value : value;
      }
  })


  newTime.seconds = parsedTime.seconds;
  newTime.minutes = parsedTime.minutes;
  newTime.hours = parsedTime.hours;

  return newTime;
}
export const bandwidthMultiplierConstant = 0.08
export const storageMultiplierConstant = 0.05

export const PlaylistOrCommentsOrMarkerDisplayOptions=[
  {
    id:'1',
    name:'Inside The Player'
  },
  {
    id:'2',
    name:'Outside The Player'
  },
  {
    id:'3',
    name:'Under The Player'
  },

]
export const LiveChatDisplayOptions=[
  {
    id:'1',
    name:'Inside The Player'
  },
  {
    id:'2',
    name:'Outside The Player'
  },
]

export const cancelSubscriptionsReasonForCost = {
  message :`<p>Wait! We may have a lower-priced membership level that suits you... Since we offer a wide range of pricing options and features, there may be a better pricing option for you.<br/><br/>We encourage you to <a href="https://spotlightr.com/pricing" target="_blank">check out all pricing options</a> before making your final decision. When you find a better option just contact support and we'll take care of it for you.</p>`,
  beforeClickMessage: `Before you click Cancel Subscription, we would love to know what price would change your mind:`
}

export const cancelSubscriptionsReasonForDifficult = {
  message:`<p>We're here to help - let's find a solution to the problem you're facing with help from a real person. One of our Customer Happiness Specialists will guide your through anything you need. They can even log into your account and set it up for you while recording it to show you what was done.</p>`,
  beforeClickMessage: `Before you click Cancel Subscription, we would love to know what solution would change your mind:`
}

export const cancelSubscriptionsReasonForMissing ={
  message: `<p>Wait! It's very possible that Spotlightr does support the feature you need. Let's double-check and see if Spotlightr can perform the function you need. Click the button below to start a live chat with one of our Spotlightr functionality experts.</p>`,
  beforeClickMessage:`Before you click Cancel Subscription, please let us know what function or feature is missing from Spotlightr:`
}

export const cancelSubscriptionsReasonForOther ={
  message: `<p>Do you know about some of the features that make Spotlightr unique? Our amazing Customer Support Team is here to serve you and find solutions. Feel free to contact us now so we can solve the problems you're experiencing.</p>`,
  beforeClickMessage: `Before you click Cancel Subscription, please let us know what other product(s) are you using:`
}

export const cancelSubscriptionsReasonForNotUsing = {
  message:`<p>Do you know that our experts can help you with anything you need regarding your course or website? If there's something holding you back we can probably help! Our amazing Customer Support Team is here to serve you and find solutions. Let's get on a chat and we can see if we can help you get back on track.</p>`,
  beforeClickMessage:`Before you click Cancel Subscription, please let us know why you've decided to not use Spotlightr:`
}

export const cancelSubscriptionsReasonForSomethingElse ={
  beforeClickMessage: `Please tell us about your reason for canceling. We will get in touch if we feel there's something we can do:`
}

export const OnboardingSurveyForusecaseOptions = [
  {
    id:1,
    value:'PaidOnline',
    label:'Paid online course material'
  },
  {
    id:2,
    value:'SuppportOrTraining',
    label:'Customer product support or employee training'
  },
  {
    id:3,
    value:'MarketingOrSales',
    label:'Marketing or sales'
  },
  {
    id:4,
    value:'Other',
    label:'Other'
  },
]

export const onBoardingSurveyArray = [
  {
    id:1,
    type:'usecase',
    currentStep: 1,
    title:`Which of the following best describes how you use video?`,
    surveyQuestion : [
      {
        id:1,
        value:'PaidOnline',
        label:'Paid online course material'
      },
      {
        id:2,
        value:'SuppportOrTraining',
        label:'Customer product support or employee training'
      },
      {
        id:3,
        value:'MarketingOrSales',
        label:'Marketing or sales'
      },
      {
        id:4,
        value:'Other',
        label:'Other'
      },
    ]
  },
  {
    id:2,
    type:'primaryNeed',
    currentStep: 2,
    title:`Which best describes the #1 reason you signed up for Spotlightr?`,
    surveyQuestion : [
      {
        id:1,
        value:'marketingTools',
        label:'Marketing tools to grow leads and sales'
      },
      {
        id:2,
        value:'learningTools',
        label:'Learning tools to enhance courses and improve student experience'
      },
      {
        id:3,
        value:'competitivePricing',
        label:'Competitive pricing'
      },
      {
        id:4,
        value:'videoPlayerCustomization',
        label:'Video player customization and branding options'
      },
      {
        id:5,
        value:'securingMyVideos',
        label:'Securing my videos and controlling access'
      },
      {
        id:6,
        value:'Other',
        label:'Other'
      },
    ]

  },
  {
    id:3,
    type:'previousSolution',
    currentStep: 3,
    title:`Are you switching from another provider?`,
    surveyQuestion : [
      {
        id:1,
        value:'firstVideoHostingProvider',
        label:`No you're my first video hosting provider`
      },
      {
        id:2,
        value:'youtube',
        label:'Yes from Youtube'
      },
      {
        id:3,
        value:'wistia',
        label:'Yes from Wistia'
      },
      {
        id:4,
        value:'vimeo',
        label:'Yes from Vimeo'
      },
      {
        id:5,
        value:'Other',
        label:'Yes from other'
      },
    ]

  },
  {
    id:4,
    type:'currentSituation',
    currentStep: 4,
    title:`What made you choose to trial Spotlightr over similar platforms?`,
    // title:`If you had to choose just one, what’s the biggest reason you chose to trial Spotlightr over similar platforms?`,
    surveyQuestion:[]
  },
]
export const displayBandwidthPercentage = 25

export const getValueByKey=(data,value,key)=>{
    const index = data.findIndex(x=>x[key] === value)
    if(index >= 0)
      return data[index]
    return null
}

export const encodeTextEditorString = (value) => {
  value = value.replace(/"/g, "&quot;")
  value = value.replace(/'/g, "&#039;")
  value = value.replace(/&/g, "%26")
  // value = value.replace(/amp;/g, "")
  return value
}
export const decodeTextEditorString = (value) => {
  value = value.replace(/&quot;/g,'"')
  value = value.replace(/&#039/g, "'")
  value = value.replace(/%26/g,'&')
  value = value.replace(/""/g,'"')
  return value
}
export const objToQuery = (obj, parentKey = '',excludesKeys=[]) => Object.keys(obj).map((k) => {
  const key = encodeURIComponent(k);

  if (Array.isArray(obj[k]) && !excludesKeys.includes(k)) {
      return obj[k].map(v => `${parentKey}[${key}][]=${encodeURIComponent(v)}`).join('&');
  } 
  if (typeof obj[k] === 'object') {
      return objToQuery(obj[k], parentKey !== '' ? `${parentKey}[${key}]` : key);
  } 
  return `${parentKey}${key}=${encodeURIComponent(obj[k])}`;
  
})
.filter(Boolean)
.join('&');

export const timeControlOption = [
  { id: '1', label: 'At Start', value: 'At Start' },
  { id: '2', label: 'At End', value: 'At End' },
  { id: '3', label: 'Custom', value: 'custom' },
];
export const sparkVideoLimit =  25

/* eslint-disable no-useless-escape */
export const isValidURL = (URL) => {
  const url = URL.replace(/ /g, "%20");
  return /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
}

export const insertDataAtPosition = (key,value,obj,pos)=>(
  Object.keys(obj).reduce((ac,a,i) => {
    if(i === pos) ac[key] = value;
    ac[a] = obj[a]; 
    return ac;
  }, {})
)
export const reFormTranslations=(languages,languageSelectedValue,translations)=>{
  if(translations && Object.keys(translations).length > 0){
    const data = {...translations}
    translations = {}
    const sourceLanguageTranslation = languages.find(x=>x.sourceLanguage === languageSelectedValue.sourceLanguage)
      Object.keys(data).forEach((currentTranslation)=>{
        if(currentTranslation !== sourceLanguageTranslation?.translation){
          translations = {
            ...translations,
            [currentTranslation]: true
          }
        }
      })
      const translationsData = insertDataAtPosition(sourceLanguageTranslation?.translation,true,translations,0)
      return translationsData
  }
  return translations
}

export const calculatePreviousMonthsBandwidthAverage = (previousMonths, date, res) => {
  const previousMonthsAverages = []
  const previousMonthsAveragesMonth = []
  previousMonths.forEach((value, index) => {
    let totalAWSAmount = 0
    const previousMonthFirstDay = moment(new Date(date.getFullYear(), date.getMonth() - value, 1)).format("YYYY-MM-DD");
    const previousMonthEndDay = moment(new Date(date.getFullYear(), date.getMonth() - index, 0)).format("YYYY-MM-DD");   

    const previousMonthData = res.filter(a => previousMonthFirstDay <= a.date && a.date <= previousMonthEndDay)

    if(previousMonthData && previousMonthData.length > 0){
      previousMonthData.forEach((value)=>{
        const awsAmount = Number((value.awsAmount ? (value.awsAmount / 1000).toFixed(2) : 0))
        totalAWSAmount += awsAmount                    
      })

      previousMonthsAverages.push(totalAWSAmount.toFixed(2))
    }
    else{
      previousMonthsAverages.push('')
    }
    previousMonthsAveragesMonth.push(new Date(date.getFullYear(), date.getMonth() - index, 0).toLocaleString('default', { month: 'long' }))
  })
  return ({previousMonthsAverages, previousMonthsAveragesMonth})
}

export const calculateDays = (startDate, endDate) => {
  const d1 = new Date(startDate);
  const d2 = new Date(endDate);
  const diff = d2.getTime() - d1.getTime();   
  return diff / (1000 * 60 * 60 * 24);  
}

export const setDefaultPlayerSettings=(type = null)=>{
  const playerSettings = {
    skin: 'default',
    skinStyle: 'modern',
    showControls: false,
    fullScreen: true,
    buffer: true,
    autosize: true,
    overlays: [],
    subtitleTracks: [],
    finishAction: {},
    quiz: {},
    youtubeSource: '',
    button: { animation: 'pulse', size: 'default', hasBorder: false, shape: 'default' },
    share: { active: false, url: '', tags: '' },
    jsonLD: false,
    colors: {
      skin: 'rgba(255, 255, 255, 0)',
      controls: 'rgba(255, 255, 255, 1)',
      bars: 'rgba(0, 0, 0, 1)',
      shadows: 'rgba(0, 0, 0, .33)',
    },
  };
  if(type){
    playerSettings.type = type
  }
  return playerSettings
}

export const videoProgressionPercents=[
  {
    id:'1',
    value: '25',
    name:'25%'
  },
  {
    id:'2',
    value: '50',
    name:'50%'
  },
  {
    id:'3',
    value: '75',
    name:'75%'
  },
  {
    id:'4',
    value: '95',
    name:'100%'
  },

]

export const getShareUrl=(encodedId, currentTheme, user, type)=>{
  let domain = null
    let subDomain = null
    if(currentTheme?.subdomain)
      subDomain = `${currentTheme?.subdomain}`
    else {
      domain = currentTheme?.domain
    }
    const shareLink = getModuleShareUrl(user, encodedId, type, domain, false, subDomain)
    return shareLink
}

export const disableTab = (array,prop) => {
  const isDisable = array.indexOf(prop) > -1
  return isDisable
}

export const IsJsonString = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const prepareDataForCsvExport = (clickedRow) => {
  const rowsToExport = ['customViewerID', 'email', 'videoID', 'date', 'city', 'country_name', 'device', 'IP', 'referer', 'percentWatched']
  let arrayToExport = []
  if(clickedRow?.views){
    arrayToExport = clickedRow?.views.map(item => {
      const viewsData = {}
      Object.entries(item).forEach(([key,value]) => {
        if(rowsToExport.indexOf(key) > -1) viewsData[key] = value
      })
      return viewsData
    })
  }
  return arrayToExport
}

export const prepareDataForCsvVideoQuizQuestionResposes = (questions, videoId) => {
  const arrayToExport = []
  if(questions){
    questions.forEach((question) => {
      const questionID = question.id
      const {questionText} = question

      Object.values(question.answers).forEach((answer) => {
        const questionData = {}
        questionData.quizID = videoId
        questionData.questionID = questionID
        questionData.viewerID = answer.email
        questionData.questionText = questionText
        questionData.answerText = answer.answerText
        questionData.status = answer.status
        arrayToExport.push(questionData)
      })    
    })
  }
  return arrayToExport
}

export const getQuizResultData = (quizData, isVideoQuiz, video = null, videos = null) => {
  const qData={}
  quizData.forEach(quiz => {
    const {data,viewRecords, score, videoID} = quiz
    if(isVideoQuiz && !viewRecords) return;
    if(!isVideoQuiz && !viewRecords[0]) return;
    
    let total = 0
    data.forEach(marker =>  {          
      if(marker.quizType !== "identify") {
        total += marker.points? marker.points: 1;
      }
    })

    let passingGradeValue = 100
    let resultStatus = false

    if(isVideoQuiz){
      if(video && video.playerSettings.quiz && video.playerSettings.quiz.passingGrade){
        passingGradeValue = video.playerSettings.quiz.passingGrade
      }
      if(videos){
        const currentVideo = videos.find(x => x.id === videoID)
        if(currentVideo?.playerSettings?.quiz){
          passingGradeValue = currentVideo.playerSettings.quiz.passingGrade ? currentVideo.playerSettings.quiz.passingGrade : passingGradeValue
        }
      }
      resultStatus = (score / total) * 100 >= passingGradeValue
    }
    else
      resultStatus = data[data.length-1].status
    
    const quizDetails = {
      score: score !== 0 ? `${score} ${total ? `/${total}` : ''}` : 0,
      status: resultStatus ? "PASSED" : "FAILED",
      data
    }
    qData[isVideoQuiz ? viewRecords.id : viewRecords[0].id] = quizDetails
  })
  return qData
}

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export const StreamIdleStatus = "idle"
export const StreamActiveStatus = "active"
export const StreamConnectedStatus = "connected"
export const StreamCompletedStatus = "completed"
export const StreamDisconnectedStatus = "disconnected"

export const liveChatIframeUrl='https://deadsimplechat.com/'

export const setThemeIconColor = (value, theme, colorType) => {
  if(value && Array.isArray(value)){
    return value.length > 0 ? theme.palette.primary[colorType] : ''
  }
  if(value && typeof value === "object"){
    return Object.keys(value).length > 0 ? theme.palette.primary[colorType] : ''
  }
  return value && value !== "undefined" ? theme.palette.primary[colorType] : ''
}

export const activityFilters = [
  { label: 'All Views', value: 'allViews' },
  { label: 'Videos Only', value: 'videosOnly' },
  { label: 'Galleries & Courses', value: 'galleryOnly' },
  { label: 'Quiz Only', value: 'quizOnly' },
];

export const commentFilters = [
  { label: 'All Comments', value: 'allComments' },
  { label: 'Reactions Only', value: 'reactionOnly' },
  { label: 'Comments Only', value: 'commentOnly' },
];

// export const emailOverlay19 = {
//   "id": 19,
//   "name": "Optin Email",
//   "category": "5",
//   "thumbnail": "optin-fullscreen.png",
//   "screenTarget": "desktop",

//   "controls": {
//       "enabled": true,
//       "time": {
//           "expanded": "expanded",
//           "enabled": true
//       },
//       "content": {
//           "expanded": "expanded",
//           "enabled": true,
//           "background": "rgba(0,0,0,.8)",
//           "color": "white",
//           "headline": {
//             "enabled": true,
//             "text": "Optin lock",
//             "color": "white"
//           },
//           "customHTML": {
//             "enabled": true,
//             "code": "This is locked",
//             "color": "white"
//           },
//           "continueBtn": {
//             "enabled": true,
//             "label": "Unlock Button",
//             "btnText": "Continue Watching"
//           }
//       },
//       "optin": {
//           "expanded": "expanded",
//           "enabled": true,
//           "consent": false,
//           "integrations": [],
//           "consentText": 'By entering your email address, you agree to receive our marketing emails. Please click the link below to view our terms and conditions for further information about how your data is used and stored, including how to opt out.',
//           "hideImputFields": true,
//           "showValidateEmailCheckbox": true
//       },
//       "settings": {
//           "expanded": false,
//           "enabled": true,
//           "progress": false,
//           "opacity": 1,
//           "closable": false,
//           "border": "",
//           "desktopDisplay": true,
//           "mobileDisplay": true,
//           "pauseOnShow": true
//       },
//       "animation": {
//         "expanded": false,
//         "enabled": true,
//         "in": {
//           "type": "fade",
//           "direction": "up"
//         },
//         "out": {
//           "type": "fade",
//           "direction": "up"
//         },
//         "continuous": {
//           "type": "fade",
//           "direction": "up"
//         }
//       },
//       "position": {
//           "expanded": false,
//           "enabled": false,
//           "fullScreen": 1
//       }
//   }
// }

// export const smsOverlay20 = {
//   "id": 20,
//   "name": "Optin SMS",
//   "category": "5",
//   "thumbnail": "optin-fullscreen.png",
//   "screenTarget": "desktop",

//   "controls": {
//       "enabled": true,
//       "time": {
//           "expanded": "expanded",
//           "enabled": true
//       },
//       "content": {
//           "expanded": "expanded",
//           "enabled": true,
//           "background": "rgba(0,0,0,.8)",
//           "color": "white",
//           "headline": {
//             "enabled": true,
//             "text": "SMS lock",
//             "color": "white"
//           },
//           "customHTML": {
//             "enabled": true,
//             "code": "This is locked",
//             "color": "white"
//           },
//           "continueBtn": {
//             "enabled": true,
//             "label": "Unlock Button",
//             "btnText": "Continue Watching"
//           }
//       },
//       "settings": {
//           "expanded": false,
//           "enabled": true,
//           "progress": false,
//           "opacity": 1,
//           "closable": false,
//           "border": "",
//           "desktopDisplay": true,
//           "mobileDisplay": true,
//           "pauseOnShow": true
//       },
//       "animation": {
//         "expanded": false,
//         "enabled": true,
//         "in": {
//           "type": "fade",
//           "direction": "up"
//         },
//         "out": {
//           "type": "fade",
//           "direction": "up"
//         },
//         "continuous": {
//           "type": "fade",
//           "direction": "up"
//         }
//       },
//       "position": {
//           "expanded": false,
//           "enabled": false,
//           "fullScreen": 1
//       }
//   }
// }

// export const callOverlay21 = {
//   "id": 21,
//   "name": "Tap to call",
//   "category": "5",
//   "thumbnail": "optin-fullscreen.png",
//   "screenTarget": "desktop",

//   "controls": {
//       "enabled": true,
//       "time": {
//           "expanded": "expanded",
//           "enabled": true
//       },
//       "content": {
//           "expanded": "expanded",
//           "enabled": true,
//           "background": "rgba(0,0,0,.8)",
//           "color": "white",
//           "contact": {
//             "enabled": true,
//             "number": "",
//             "callBtnText": "Call now"
//           }
//       },
//       "settings": {
//           "expanded": false,
//           "enabled": true,
//           "progress": false,
//           "opacity": 1,
//           "closable": false,
//           "border": "",
//           "desktopDisplay": true,
//           "mobileDisplay": true
//       },
//       "animation": {
//         "expanded": false,
//         "enabled": true,
//         "in": {
//           "type": "fade",
//           "direction": "up"
//         },
//         "out": {
//           "type": "fade",
//           "direction": "up"
//         },
//         "continuous": {
//           "type": "fade",
//           "direction": "up"
//         }
//       },
//       "position": {
//           "expanded": false,
//           "enabled": true,
//           "fullScreen": 0,
//           "position": 'end end',
//           "allowedSelection": "grid"
//       }
//   }
// }
export const thinScrollbarStyle= {
  overflow: 'hidden',
  scrollbarWidth: 'thin',
  scrollbarColor: '#B7B7B7 transparent',
  '&:hover':{
    overflow:"auto"
  },
  '&:hover::-webkit-scrollbar': {
    opacity: 1,
    width: '0.4em',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar': {
    opacity:0,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#B7B7B7',
    borderRadius: '8px'
  },
  '&::-webkit-scrollbar-thumb:focus': {
    backgroundColor: '#adadad'
  },
  '&::-webkit-scrollbar-thumb:active': {
    backgroundColor: '#adadad'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#adadad'
  },
  '&::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent'
  }
}
export const leadGenerationClass = ['optin','sms','call']
export const overlaysClass = ['new-templates-directive']

export const defalutHeaderColor = "#5a5a5a" // dialog box headercolor
export const defaultDialogBackgroundColor = "#fff" // dialog background color
export const defaultButtonBackgroundColor = "#007A7A" // dialog button background color
export const defaultButtonHoverColor = "#5a5a5a" // dialog button hover color
export const defaultLinkColor = "red"
export const defaultLinkHoverColor = "yellow"
export const defaultOverlayBackgroundColor = "#000000a8"

export const defaultAppletUserToken = "mEfRW4cemeKDQXVFyQnxqJIDb6uw7lEVHbokMydTguM970CQ4kYSNp23dyrjNZ1YRHX7LutQbGnAThh6hTu6wBTn9ePW7iNLf2oSUX3tsxQgI081P1AKsJx7p1wW1iLiakHbPlRrgHX9vfo3mgtpbbmY8dKroQ4EpFXcXIGs4pGmzwBwVJJ9ohMcUiCzJWkXPkTegn8MBP9VluqUcqHczpDyDGPG0NghcME1iG9l3CtYJdcue74HUUzhx8-GEN"
export const defaultAppletUserId = 99134227 // 99128117
export const defaultAppletVideoGroup = 36270 // 35707
export const defaultAppletThemeId = 10197 // 10164
export const defaultGigabyteToByteForApplet = 1073741824

export const SERVER_LOC = 'https://sh9z96knae.execute-api.us-east-1.amazonaws.com/prod'; // Location of the server
export const defaultFilesizeForMultipartFileUpload = 30720
export const default30MBSize = 30720
export const default500MBSize = 512000
export const default1GBSize = 1048576