/* eslint-disable prefer-arrow-callback */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

// GA
// import ReactGA from 'react-ga';
import ReactGA from "react-ga4";

import { useSnackbar } from 'notistack';

import { HOST_API, SPOTLIGHTR_VERSION } from 'src/config';

import { bindScriptToHead, executeCrazyEggScript, trackEventActionsFunction } from 'src/utils/common';

// utils
import Cookies from 'js-cookie';
import axios from '../utils/axios';
import { setSession } from '../utils/session';


// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isDeactivated:false,
  userCancelStatusUpdated:false,
  userSurvey:{},
  isApplet: false,
  isAudienceProfile: false,
  badgeViewers:0
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user,userCancelStatusUpdated } = action.payload;
    if(user)
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        isDeactivated:false,
        userCancelStatusUpdated,
        user,
      };
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        isDeactivated:false,
        userCancelStatusUpdated
      };
  },
  LOGIN: (state, action) => {
    const { user, isApplet, isAudienceProfile } = action.payload;
    window.isApplet = isApplet
    window.isAudienceProfile = isAudienceProfile
    return {
      ...state,
      isApplet,
      isAudienceProfile,
      isAuthenticated: true,
      isDeactivated:false,
      user,
      userCancelStatusUpdated:false
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isDeactivated:false,
    user: null,
    userCancelStatusUpdated:false
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isDeactivated:false,
      user,
    };
  },
  UPDATE: (state, action) => {
    const { user,userCancelStatusUpdated } = action.payload;
    return { ...state, user,userCancelStatusUpdated };
  },
  UPDATECANCELEDSTATUS: (state, action) => {
    const { userCancelStatusUpdated,isDeactivated,isAuthenticated,isInitialized } = action.payload;
    return { ...state,userCancelStatusUpdated,isDeactivated, isAuthenticated,isInitialized};
  },
  ISDEACTIVATED: (state,action) => {
    const { user } = action.payload;
    window.isApplet = false
    window.isAudienceProfile = false
    return {
        ...state,
        isAuthenticated: false,
        isDeactivated:true,
        userCancelStatusUpdated:false,
        isApplet: false,
        isAudienceProfile: false,
        user,
    }
  },
  UPDATEUSERSURVEY: (state,action) => {
    const { userSurvey } = action.payload;
    return {
        ...state,
        userSurvey
    }
  },
  SETBADGEVIEWERS: (state,action) => {
    const { badgeViewers } = action.payload;
    return {
        ...state,
        badgeViewers
    }
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  adminLogin:() => Promise.resolve(),
  updateUserInfo:() => Promise.resolve(),
  getUser:() => Promise.resolve(),
  updateUser:() => Promise.resolve(),
  getWatcherData:() => Promise.resolve(),
  getCards:() => Promise.resolve(),
  updateUserPlan:() => Promise.resolve(),
  getUserPlans:()=> Promise.resolve(),
  getCustomerActiveSubscriptions:()=> Promise.resolve(),
  updateCanceledUser:() => Promise.resolve(),
  cancelAccount:()=> Promise.resolve(),
  settleFlex:()=> Promise.resolve(),
  setUserSurvey:()=>Promise.resolve(),
  setBadgeViewers:()=>Promise.resolve(),
  googleLoginOrRegister:()=>Promise.resolve(),
  updateUserSettings:()=>Promise.resolve(),
  googleReCAPTCHATokenVerification:()=>Promise.resolve(),
  checkValideEmail:()=>Promise.resolve(),
  verifyCode:()=>Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { enqueueSnackbar } = useSnackbar();

  const getAccessToken = (isApplet, appletAccessToken, isAudienceProfile, audienceProfileAccessToken) => {
    if(isApplet)
      return appletAccessToken
    if(isAudienceProfile)
      return audienceProfileAccessToken
    return window.localStorage.getItem('accessToken')
  }

  const getUser = async() => {
    try {
      const { isApplet, appletAccessToken, isAudienceProfile, audienceProfileAccessToken }= window
      const accessToken = getAccessToken(isApplet, appletAccessToken, isAudienceProfile, audienceProfileAccessToken)
      const teamUser = window.localStorage.getItem('teamUser') || '';

      let adminUser = false
      let amember = null
      if(isApplet || isAudienceProfile){
        adminUser = true
        amember = "amember"
      }

      await auth('INITIALIZE', accessToken, teamUser, false, adminUser, amember, isApplet, isAudienceProfile);
    } catch (err) {
      console.error(err);
      window.isApplet = false
      window.isAudienceProfile = false
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          isApplet: false,
          isAudienceProfile: false,
          user: null,
        },
      });
    }
  }

  const setUserTokenData=(user)=>{
    if(user.teamUser) setSession(user.token, user.teamUser.id)
        else setSession(user.token)
        ReactGA.set({ dimension1: user.license });
        ReactGA.set({ userId: user.id });
  }

  useEffect(() => {
    const initialize = async () => {
      await getUser()
      // try {
      //   const accessToken = window.localStorage.getItem('accessToken');
      //   const teamUser = window.localStorage.getItem('teamUser') || '';

      //   await auth('INITIALIZE', accessToken, teamUser);
      // } catch (err) {
      //   console.error(err);
      //   dispatch({
      //     type: 'INITIALIZE',
      //     payload: {
      //       isAuthenticated: false,
      //       user: null,
      //     },
      //   });
      // }
    };

    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const auth = async (type, token, teamUser = '',userCancelStatusUpdated = false, adminuser = false,from = null, applet = false, audienceProfile = false) => {
    if (token) {      
      const tokenData = `token=${token}&teamUser=${teamUser}`;
      const response = await axios.post('/auth', tokenData);
      const user = response.data[0];   
      user.isAdmin = from === "amember"      
      user.firstName = user.firstName === 'undefined' || !user.firstName ? '' : user.firstName
      
      const globalSettings = user?.globalSettings !== ''  ? { ...JSON.parse(user.globalSettings) }   :  {};
      if(globalSettings)        
        Object.entries(globalSettings).forEach(([key,value])=> globalSettings[key]=value?.length === 0 ? undefined : value)        
      window.localStorage.setItem('localGlobalSettings', JSON.stringify(globalSettings));

      if(user.canceled === 1 && !adminuser)
        type  = "ISDEACTIVATED"
      if (user.teamUser) {
        user.teamName = user.teamUser.name
        user.lastName = ''
        if(!user.teamUser.admin){
          user.restricted = true
          if(user.teamUser.permissions.groups){
            user.allowedGroups = [];
            user.teamUser.permissions.groupsAllowed.forEach(item => {
              user.allowedGroups.push(item.id);
            })
            // console.log('user.allowedGroups', user.allowedGroups);
          }
        }
      }
      try{
        if(user.whiteListedDomains) user.whiteListedDomains = JSON.parse(user.whiteListedDomains)
        else user.whiteListedDomains = []
      }
      catch(err){
        user.whiteListedDomains = []
      }
      // if(user.whiteListedDomains) user.whiteListedDomains = JSON.parse(user.whiteListedDomains)
      if(type !== "ISDEACTIVATED" && type !== "ISSUBUSER" && !applet && !audienceProfile){
        setUserTokenData(user)
        // if(user.teamUser) setSession(user.token, user.teamUser.id)
        // else setSession(user.token)
      }

      if (type === 'LOGIN'){
        window.isApplet = applet
        window.isAudienceProfile = audienceProfile
        dispatch({
          type: 'LOGIN',
          payload: {
            isApplet: applet,
            isAudienceProfile: audienceProfile,
            user,
          },
        });
      }
      else if(type === "ISDEACTIVATED"){
        window.isApplet = false
        window.isAudienceProfile = false
        dispatch({
          type: 'ISDEACTIVATED',
          payload: {
            isAuthenticated: false,
            isDeactivated:true,
            isApplet: false,
            isAudienceProfile: false,
            user,
          },
        });
      }
      else if(type === "ISSUBUSER"){
        await logout()
        enqueueSnackbar(`Subusers not supported in Spotlightr ${SPOTLIGHTR_VERSION}`,{variant:"error"})
      }
      else if(!userCancelStatusUpdated){
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            isDeactivated:false,
            userCancelStatusUpdated,
            user
          },
        });
      }
      else{
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            isDeactivated:false,
            userCancelStatusUpdated
          },
        });
      }      
      setBadgeViewers(user.id)
    } else {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
    checkForTheNewVersion()
  };

  const login = async (email, password) => {
    const emailData = encodeURIComponent(email)
    const passwordData = encodeURIComponent(password)
    const data = `username=${emailData}&password=${passwordData}`;
    const response = await axios.post('/login', data);
    const { token,canceled,subuser, teamUser } = response.data[0];
    if(subuser > 0){
      await auth('ISSUBUSER', token);
    }
    else if(canceled){
      await auth('ISDEACTIVATED', token);
    }
    else{
      await auth('LOGIN', token, teamUser?.id || '');
    }
  };
  const formSignupQuery = (query) => {
    const signupQuery = {};
    if(query.get('utm_source')) signupQuery.utm_source = query.get('utm_source');
    if(query.get('utm_campaign')) signupQuery.utm_campaign = query.get('utm_campaign');
    if(query.get('utm_medium')) signupQuery.utm_medium = query.get('utm_medium');
    if(query.get('demoAccount')) signupQuery.demoAccount = query.get('demoAccount');
    if(query.get('email')) signupQuery.email = query.get('email');
    if(query.get('productID')) signupQuery.productID = query.get('productID');

    // !vm.appSumo have to be part of statement
    if(!signupQuery.utm_source && !signupQuery.demoAccount && !signupQuery.email) {
      const spotlightrLPQS = Cookies.get('spotlightrLPQS');
      if(spotlightrLPQS){
        const LPQSString = spotlightrLPQS.split("?")[1];
        const LPQSItems = LPQSString.split("&");
        LPQSItems.forEach((value) => {
          const LPQSItem = value.split("=");
          const newItemKey = LPQSItem[0]
          const newItemValue = LPQSItem[1];
          signupQuery[newItemKey] = newItemValue
        })
      }
      else{
        signupQuery.utm_source = 'FREE Signup';
        signupQuery.utm_campaign= 'FREE Signup';
        signupQuery.utm_medium= 'Direct Access';
      }
    }
    return signupQuery;
  }
  const register = async (email, password, firstName, lastName, subdomain, query, isGoogle = false) => {
    // console.log(email, password, firstName, lastName, subdomain, query)
    const signupQuery = formSignupQuery(query);

    const appToken = '18763095f637119d787e88106a89cb64';

    let newUserData = `&productID=${signupQuery.productID || 46}&processorName=force&price=0&transID=demo&qty=1`;
    const teamMember = query.get('teamMember')
    if(teamMember){
      newUserData = `${newUserData}&teamMember=${teamMember}`
    }
    if(isGoogle){
      newUserData = `${newUserData}&isGoogle=${1}`
    }

    const data = `firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(
      lastName
    )}&username=${encodeURIComponent(email)}&password=${encodeURIComponent(
      password
    )}&subdomain=${subdomain}&signupQuery=${JSON.stringify(signupQuery)}&App-Token=${appToken}${newUserData}`;

    const response = await axios.post('/user/demo', data);

    if (response.data === 'Created.') {
      trackEventActionsFunction('Registration', 'User Signup', 'Signed Up')
      
      executeCrazyEggScript('signup')
      bindScriptToHead(`fbq('track', 'StartTrial');`)
      
      await login(email, password);
    }

    // const { accessToken, user } = response.data;

    // window.localStorage.setItem('accessToken', accessToken);
    // dispatch({
    //   type: 'REGISTER',
    //   payload: {
    //     user,
    //   },
    // });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('isAdmin')
  };

  const updateUserEndpoint = async(data)=>{
    const response = await axios.put('/user', data);
    return response
  }

  const updateUser = async (userData, rowData, preventRerender) => {
    try {
      let data = `Token=${userData.token}&id=${userData.id}&globalSettings=${userData.globalSettings}&brandedPlayer=${userData.brandedPlayer}&whiteListedDomains=${JSON.stringify(userData.whiteListedDomains)}&translations=${userData.translations}`;
      
      if(userData.zoom === '')
       data += `&zoom=`
      if(userData.stripeConnectID === ''){
       data += `&stripeConnectID=&stripeAT=`
      }

      await updateUserEndpoint(data)
      if(!preventRerender)
        dispatch({
          type: 'UPDATE',
          payload: {
            user: {...rowData},
            userCancelStatusUpdated:false
          },
        });
    } catch (err) {
      console.error(err);
    }
  };
  const updateUserSettings = async(userData,userSettings)=>{
    const queryString = `Token=${userData.token}&id=${userData.id}&whiteListedDomains=${JSON.stringify(userData.whiteListedDomains)}&smtp=${userData.smtp}&address=${userData.address}&firstName=${userData.firstName}&lastName=${userData.lastName}&phone=${userData.phone}&brandedPlayer=${userData.brandedPlayer}&defaultTheme=${userData.defaultTheme}&customDomain=${userData.customDomain}&socialProviders=${userData.socialProviders}&affiliate=${userData.affiliate}&translations=${userData.translations}&globalSettings=${userData.globalSettings}&storage=${userSettings.storage}&bandwidthAvailable=${userSettings.bandwidthAvailable}&bandwidthOverage=${Number(userSettings.bandwidthOverage)}&transcribeMinutes=${userSettings.transcribeMinutes}&streamMinutes=${userSettings.streamMinutes}&uploadLimit=${userSettings.uploadLimit}&watchMinutes=${userSettings.watchMinutes}&license=${userSettings.license}&canceled=${Number(userSettings.canceled)}&smsCredits=${userSettings.smsCredits}`;
    const response  = await updateUserEndpoint(queryString)
    dispatch({
      type: 'UPDATE',
      payload: {
        user: {...state.user,
                    storage:userSettings.storage,
                    bandwidthAvailable:userSettings.bandwidthAvailable,
                    bandwidthOverage:userSettings.bandwidthOverage,
                    transcribeMinutes:userSettings.transcribeMinutes,
                    streamMinutes: userSettings.streamMinutes,
                    uploadLimit: userSettings.uploadLimit,
                    watchMinutes: userSettings.watchMinutes,
                    license: userSettings.license,
                    canceled: userSettings.canceled,
                    smsCredits: userSettings.smsCredits
              }
      },
    });
    return response
  }
  const updateUserProfile = async (data) => {
    const userData = {...data}
    userData.firstName = encodeURIComponent(data.firstName)
    userData.lastName = encodeURIComponent(data.lastName)
    userData.username = encodeURIComponent(data.username)
    userData.address = JSON.stringify({
      country: encodeURIComponent(data.country),
      city: encodeURIComponent(data.city),
      street: encodeURIComponent(data.street),
      company: encodeURIComponent(data.company),
      taxid: encodeURIComponent(data.taxid),
      zip: encodeURIComponent(data.zip),
    })
    data.address = JSON.stringify({
      country:data.country,
      city:data.city,
      street:data.street,
      company:data.company,
      taxid:data.taxid,
      zip: data.zip,
    })

    const queryString = `Token=${userData.token}&id=${userData.id}&whiteListedDomains=${JSON.stringify(userData.whiteListedDomains)}&smtp=${userData.smtp}&address=${userData.address}&bandwidthOverage=${userData.bandwidthOverage}&firstName=${userData.firstName}&lastName=${userData.lastName}&phone=${userData.phone}&brandedPlayer=${userData.brandedPlayer}&defaultTheme=${userData.defaultTheme}&customDomain=${userData.customDomain}&socialProviders=${userData.socialProviders}&affiliate=${userData.affiliate}&translations=${userData.translations}&globalSettings=${userData.globalSettings}`
    await axios.put('/user', queryString);
    dispatch({
      type: 'UPDATE',
      payload: {
        user: {...data},
        userCancelStatusUpdated:false
      }
    });
    
    if(userData.processorName === 'stripe')
    await updateUserProfileStripeDetails(userData.processorID);
  };

  const updateUserProfileStripeDetails = async (processorID) => {
    await axios.get(`/user/stripeCustomerDetails?processorID=${processorID}`);
  };
  const checkForTheNewVersion = async () => {
    const res = await axios.get(`/version`);
    const {data} = res
    if(SPOTLIGHTR_VERSION !== data?.version)
    enqueueSnackbar(
      "Spotlightr has been updated!  Please refresh your browser for the newest version",
      {
        variant:"info",
        autoHideDuration: 5000,
        anchorOrigin: {horizontal: "center", vertical: "top"}
      }
    )
  }
  
  const updateEmail = async(userData)=>{
    const data = `Token=${userData.token}&email=${userData.username}`;
      await axios.post('/user/updateEmail', data);
      dispatch({
        type: 'UPDATE',
        payload: {
          user: {...userData},
          userCancelStatusUpdated:false
        },
      });
  }
  const updatePassword = async(userData)=>{

    const query = `username=${userData.username}&currentPassword=${userData.curentPassword}&newPassword=${userData.newPassword}&confirmPassword=${userData.confirmPassword}`;
    await axios.post('/user/changepass', query);
  }
  const createStripeSession = async(user)=>{
    if(user.processorName === 'stripe' && (user.license !== 'PRO')){
      const query = `?customer=${user.processorID}`;
      const response = await axios.get(`/user/stripeCustomerPortal${query}`);
      return response;
    }
    return null;
  }
  const getWatcherData = async(sampleEmail,integrationID)=>{
    const query = `getWatcherData?personalize=${sampleEmail}&integrationID=${integrationID}`;
    const response = await axios.get(query);
    return response;

  }
  const adminLogin = async(token,amember, isApplet = false, isAudienceProfile = false)=>{
    setSession(null)
    await auth('LOGIN',token, '', false, true,amember, isApplet, isAudienceProfile)
  }
  const getCards = async (user) => {
    const cards = await axios.get(`${HOST_API}/user/stripe/payments?userID=${user.id}&Token=${user.token}`)
    return cards
  }
  const getUserPlans = async(user)=>{
    const data = `Token=${user.token}`;
    const response = await axios.get('/plans', data);
    return response
  }
  const updateUserPlan = async (pay) => {
    const customer = `?customer=${pay.processorID}`
    const bandwidth = `&bandwidth=${pay.bandwidthQuantity}`
    const storage = `&storage=${pay.storageQuantity}`
    const yearly = `&yearly=${pay.yearlyBilling}`
    const mainPlan = `&mainPlan=${pay.name}`;
    const coupon = pay.coupon && pay.yearlyBilling ? `&coupon=${pay.coupon}` : '&coupon=0';
    // const couponDiscountValue = pay.coupon && pay.yearlyBilling ?`&couponDiscountValue=${pay.couponDiscountValue}` : '&coupon=0'
    const response = await axios.get(
      `/fix/createPolaris${customer}${bandwidth}${storage}${yearly}${coupon}${mainPlan}`
    )
    return response
  }

  const updateUserInfo=async(userData)=>{
    dispatch({
      type: 'UPDATE',
      payload: {
        user: {...userData},
        userCancelStatusUpdated:false
      },
    });
  }
  const getCustomerActiveSubscriptions = async (user) => {
      const data = `?customerID=${user.processorID}`;
      const response = await axios.get(`/getCustomerActiveSubscriptions${data}`);
      return response
  }
  const setUserSurvey = (data)=>{
    dispatch({
      type: 'UPDATEUSERSURVEY',
      payload: {
        userSurvey:data
      },
    });
  }
  const setBadgeViewers = (userID)=>{
    axios.get(`/badgeViewers?userID=${userID}`).then(res =>dispatch({
      type: 'SETBADGEVIEWERS',
      payload: {
        badgeViewers:res.data
      },
    }))
    
  }
  const updateCanceledUser=async(userData,userCancelStatus)=>{
    if(userCancelStatus && userData){
      const userObj={...userData}
      userObj.canceled = 0;
      setUserTokenData(userObj)
      dispatch({
        type: 'UPDATECANCELEDSTATUS',
        payload: {
          userCancelStatusUpdated:userCancelStatus,
          isAuthenticated:true,
          isDeactivated:false,
          isInitialized:true,
          user:{...userObj}
        },
      });
    }
    else{
      dispatch({
        type: 'UPDATECANCELEDSTATUS',
        payload: {
          userCancelStatusUpdated:false,
          isAuthenticated:true,
          isDeactivated:false,
          isInitialized:true
        },
      });
    }

  }
  const cancelAccount=async (user,license,cancelReason)=>{
    let stripe = 0;
    let braintree = 0;
    if (user.processorName === 'stripe') {
      stripe = 1;
    } else if (user.processorName === 'braintree') {
      braintree = 1;
    }
    const tokenData = `Token=${user.token}&subID=${license.stripeID}&reason=${cancelReason}&braintree=${braintree}&title=${license.title}'&stripe=${stripe}&followup=${license.followup}`;
    const response = await axios.post('/user/cancel', tokenData);
    return response;
  }
  const settleFlex = async (GB, processorID) => {
    const response =  await axios.get(`/user/settleFlex?GB=${GB}&customer=${processorID}`)
    return response
  }
  const googleLoginOrRegister = async(token)=>{
    const headersData={
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
    const response = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {headers: headersData })
    return response
                   
  }

  const googleReCAPTCHATokenVerification = async(token) => {
    const response = await axios.get(`/captcha?token=${token}`)
    return response
  }

  const checkValideEmail = async(email) => {
    const response = await axios.get(`/validate?email=${email}`)
    return response
  }

  const verifyCode = async(email, code) => {
    const response = await axios.get(`/user/verify?email=${email}&code=${code}`)
    return response
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        updateUser,
        updateUserProfile,
        updateEmail,
        updatePassword,
        adminLogin,
        getCards,
        updateUserInfo,
        createStripeSession,
        getWatcherData,
        getUser,
        updateUserPlan,
        getUserPlans,
        getCustomerActiveSubscriptions,
        updateCanceledUser,
        cancelAccount,
        settleFlex,
        setUserSurvey,
        setBadgeViewers,
        googleLoginOrRegister,
        updateUserSettings,
        googleReCAPTCHATokenVerification,
        checkValideEmail,
        verifyCode
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
